<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="auto">
            <v-btn
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              @click="onBack()">
              <v-icon>
                mdi-chevron-left
              </v-icon>
              <span>
                ย้อนกลับ
              </span>
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="auto">
            <v-btn
              color="warning"
              depressed
              :ripple="false"
              style="width: fit-content;"
              @click="onEdit()">
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
              <span>
                แก้ไขข้อมูล
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg secondary--text">
          <v-col cols="12">
            <h3>
              ข้อมูลการจอง
            </h3>
            <v-divider class="mt-3" />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div class="mb-1">
              เลขที่การจอง
            </div>
            <div>
              {{ formData.orderNo || '231218-XXXXXX' }}
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              สถานะการจอง
            </div>
            <v-select
              v-model="formData.state"
              :rules="textBoxRules"
              :items="states"
              item-text="text"
              item-value="value"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              แนบสลิป
            </div>
            <file-uploader
              v-model="files"
              :max-file="1"
              label="อัปโหลดสลิป"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              จำนวนผู้เดินทางทั้งหมด
            </div>
            <v-divider class="my-2" />
            <v-row>
              <v-col
                cols="12"
                md="10">
                <passengers-section
                  v-model="formData.passengers"
                  disabled />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              รายละเอียดการจอง
            </div>
            <v-divider class="my-2" />
            <v-row>
              <v-col
                cols="12"
                md="6">
                <div class="mb-1">
                  แพ็กเกจทัวร์
                </div>
                <v-autocomplete
                  v-model="formData.tour"
                  :items="tours"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="tourQuery.search"
                  placeholder="EGYPT ไคโร กีซา เมมฟิส อเล็กซานเดรีย"
                  :rules="textBoxRules"
                  prepend-inner-icon="mdi-magnify"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  return-object
                  required
                  :loading="loading"
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="6">
                <div class="mb-1">
                  รอบการเดินทาง
                </div>
                <v-select
                  v-model="formData.package"
                  :items="tourPackages"
                  item-value="startDate"
                  return-object
                  outlined
                  dense
                  placeholder="โปรดเลือกแพ็กเกจทัวร์"
                  hide-details
                  required
                  :rules="textBoxRules"
                  disabled>
                  <template #selection="{ item }">
                    <span>
                      {{ item.startDate | dateFormat() }} - {{ item.endDate | dateFormat() }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      {{ item.startDate | dateFormat() }} - {{ item.endDate | dateFormat() }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div class="mb-1">
              รหัสสมาชิก
            </div>
            <v-text-field
              v-model="formData.booker.memberId"
              placeholder="ALLXXXX-XXXXX"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div class="mb-1">
              คำนำหน้า
            </div>
            <v-select
              v-model="formData.booker.prefix"
              :rules="textBoxRules"
              :items="prefixes"
              item-text="text"
              item-value="value"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              ชื่อ
            </div>
            <v-text-field
              v-model="formData.booker.firstName"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              นามสกุล
            </div>
            <v-text-field
              v-model="formData.booker.lastName"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              หมายเลขโทรศัพท์
            </div>
            <v-text-field
              v-model="formData.booker.tel"
              type="text"
              :rules="[...textBoxRules, ...telBoxRules]"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              อีเมล
            </div>
            <v-text-field
              v-model="formData.booker.email"
              :rules="emailBoxRules"
              type="email"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
            </div>
            <v-text-field
              v-model="formData.booker.citizenNo"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              รูปภาพ Passport
            </div>
            <v-divider class="mt-2 mb-4" />
            <image-uploader
              v-model="formData.passports"
              name="passports"
              multiple
              :max-file="getFollowers().length"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              รายชื่อผู้ร่วมเดินทาง
            </div>
            <v-divider class="mt-3 mb-4" />
            <v-row>
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-row flex-md-column align-center"
                style="height: fit-content; gap: 1rem; overflow-x: auto;">
                <v-btn
                  v-for="(follower, f) in formData.followers"
                  :key="`follower-btn-${f}`"
                  color="primary"
                  outlined
                  block
                  :ripple="false"
                  depressed
                  x-large
                  @click="selectFollower(f)">
                  <div
                    class="d-flex flex-column justify-center"
                    style="gap: 0.5rem;">
                    <span
                      :class="{
                        'text-decoration-underline': f === followerFocusIndex
                      }">
                      ผู้ร่วมเดินทาง {{ f + 1 }}
                    </span>
                    <span class="accent--text">
                      {{ `${follower.firstName || 'ชื่อ'} ${follower.lastName || 'นามสกุล'}` }}
                    </span>
                  </div>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="9">
                <v-row v-if="formData.followers.length">
                  <v-col
                    cols="12"
                    md="4">
                    <div class="mb-1">
                      คำนำหน้า
                    </div>
                    <v-select
                      v-model="formData.followers[followerFocusIndex].prefix"
                      :rules="textBoxRules"
                      :items="prefixes"
                      item-text="text"
                      item-value="value"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      disabled />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <div class="mb-1">
                      ชื่อ
                    </div>
                    <v-text-field
                      v-model="formData.followers[followerFocusIndex].firstName"
                      :rules="textBoxRules"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      disabled />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <div class="mb-1">
                      นามสกุล
                    </div>
                    <v-text-field
                      v-model="formData.followers[followerFocusIndex].lastName"
                      :rules="textBoxRules"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      disabled />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      หมายเลขโทรศัพท์
                    </div>
                    <v-text-field
                      v-model="formData.followers[followerFocusIndex].tel"
                      type="text"
                      :rules="[...textBoxRules, ...telBoxRules]"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      disabled />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
                    </div>
                    <v-text-field
                      v-model="formData.followers[followerFocusIndex].citizenNo"
                      :rules="textBoxRules"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      disabled />
                  </v-col>
                </v-row>
                <div
                  v-else
                  class="accent--text d-flex justify-center align-center"
                  style="height: 100%;">
                  ไม่มีผู้ร่วมเดินทาง
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              ประเภทห้อง
            </div>
            <v-divider class="mt-2 mb-4" />
            <div
              v-if="!tourRooms.length"
              class="accent--text">
              โปรดเลือกแพ็กเกจทัวร์
            </div>
            <v-row v-else>
              <v-col
                v-for="(room, r) in formData.rooms"
                :key="`order-room-${r}`"
                cols="12">
                <v-row>
                  <v-col
                    cols="12"
                    md="5">
                    <v-select
                      v-model="formData.rooms[r].name"
                      :items="tourRooms"
                      item-text="text"
                      item-value="name"
                      color="primary"
                      label="ประเภทห้อง"
                      placeholder="โปรดเลือก"
                      outlined
                      dense
                      return-object
                      required
                      hide-details
                      :rules="textBoxRules"
                      disabled />
                  </v-col>
                  <v-col
                    cols="12"
                    md="5">
                    <v-row>
                      <v-col
                        v-for="(mate, m) in room.roommates"
                        :key="`order-room-${r}-mate-${m}`"
                        cols="12">
                        <v-text-field
                          v-model="formData.rooms[r].roommates[m]"
                          :label="`ผู้เข้าพักคนที่ ${m + 1}`"
                          placeholder="นายสมจิตร ใจดี..."
                          color="primary"
                          outlined
                          dense
                          hide-details
                          disabled />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              ที่นั่งบนเครื่องบิน
            </div>
            <v-divider class="my-2" />
            <div
              v-if="!tourSeats.length"
              class="accent--text">
              โปรดเลือกแพ็กเกจทัวร์
            </div>
            <v-row v-else>
              <v-col
                v-for="(seat, r) in formData.seats"
                :key="`order-seat-${r}`"
                cols="12">
                <v-row>
                  <v-col
                    cols="12"
                    md="5">
                    <v-select
                      v-model="formData.seats[r].name"
                      :items="filterSeats"
                      item-text="text"
                      item-value="name"
                      color="primary"
                      label="อัปเกรดที่นั่ง"
                      placeholder="โปรดเลือก"
                      outlined
                      dense
                      return-object
                      required
                      hide-details
                      :rules="textBoxRules"
                      disabled />
                  </v-col>
                  <v-col
                    cols="12"
                    md="5">
                    <v-select
                      v-model.number="formData.seats[r].qty"
                      :items="availableSeats"
                      item-text="text"
                      item-value="value"
                      color="primary"
                      label="จำนวน"
                      placeholder="โปรดเลือก"
                      outlined
                      dense
                      required
                      hide-details
                      :rules="textBoxRules"
                      disabled />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              ที่อยู่สำหรับจัดส่งเอกสาร
            </div>
            <v-divider class="mt-3" />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              ชื่อ
            </div>
            <v-text-field
              v-model="formData.documentAddress.firstName"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              นามสกุล
            </div>
            <v-text-field
              v-model="formData.documentAddress.lastName"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="5">
            <div class="mb-1">
              หมายเลขโทรศัพท์
            </div>
            <v-text-field
              v-model="formData.documentAddress.tel"
              type="text"
              :rules="telBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <v-row>
              <v-col cols="12">
                <div class="mb-1">
                  ที่อยู่
                </div>
                <v-text-field
                  v-model="formData.documentAddress.address"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="3">
                <div class="mb-1">
                  ถนน
                </div>
                <v-text-field
                  v-model="formData.documentAddress.road"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="3">
                <div class="mb-1">
                  ซอย
                </div>
                <v-text-field
                  v-model="formData.documentAddress.alley"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="3">
                <div class="mb-1">
                  แขวง / ตำบล
                </div>
                <v-text-field
                  v-model="formData.documentAddress.subDistrict"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="3">
                <div class="mb-1">
                  เขต / อำเภอ
                </div>
                <v-text-field
                  v-model="formData.documentAddress.district"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="6">
                <div class="mb-1">
                  จังหวัด
                </div>
                <v-text-field
                  v-model="formData.documentAddress.province"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
              <v-col
                cols="12"
                md="6">
                <div class="mb-1">
                  รหัสไปรษณีย์
                </div>
                <v-text-field
                  v-model="formData.documentAddress.postcode"
                  type="number"
                  :rules="postcodeRules"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  disabled />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <div>
              หมายเหตุอื่นๆ เพิ่มเติม
            </div>
            <v-divider class="mt-2 mb-4" />
            <v-textarea
              v-model="formData.note"
              placeholder="มีผู้สูงอายุมาด้วย..."
              color="primary"
              outlined
              dense
              hide-details
              required
              disabled />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg secondary--text mt-6">
          <v-col cols="12">
            <h3>
              สรุปออเดอร์
            </h3>
            <v-divider class="mt-3" />
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              เลขที่แพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span v-if="formData.tour">
              {{ formData.tour.id }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              ชื่อแพ็กเกจ
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span v-if="formData.tour">
              {{ formData.tour.name }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              เวนเดอร์
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span v-if="formData.tour">
              {{ formData.tour.vendor.name }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              จำนวนคนทั้งหมด
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span>
              {{ `${passengersQty} คน` }}
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  ผู้ใหญ่
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span>
              {{ `${formData.passengers.adultWithTicket + formData.passengers.adultWithOutTicket} คน` }}
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  เด็ก
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span>
              {{ `${formData.passengers.kidWithTicket + formData.passengers.kidWithOutTicket} คน` }}
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              ประเภททัวร์
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7">
            <span v-if="formData.tour">
              {{ formData.tour.type }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            md="10">
            <span class="font-weight-bold">
              ราคาผู้ใหญ่
            </span>
          </v-col>
          <v-col cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  รวมตั๋วเครื่องบิน
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span
              v-if="formData.package"
              class="accent--text">
              {{ `${showNumberFormat(getPackage('adult').price)} x ${getTickets('adult').quantity}` }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3"
            class="text-right">
            <span v-if="formData.package">
              {{ `฿ ${showNumberFormat(getTickets('adult').net)}` }}
            </span>
          </v-col>
          <v-col cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  ไม่รวมตั๋วเครื่องบิน
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span
              v-if="formData.package"
              class="accent--text">
              {{ `${showNumberFormat(getPackage('adult').withOutTicket)} x ${getTickets('adult', false).quantity}` }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3"
            class="text-right">
            <span v-if="formData.package">
              {{ `฿ ${showNumberFormat(getTickets('adult', false).net)}` }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <span class="font-weight-bold">
              ราคาเด็ก
            </span>
          </v-col>
          <v-col cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  รวมตั๋วเครื่องบิน
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span
              v-if="formData.package"
              class="accent--text">
              {{ `${showNumberFormat(getPackage('kid').price)} x ${getTickets('kid').quantity}` }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3"
            class="text-right">
            <span v-if="formData.package">
              {{ `฿ ${showNumberFormat(getTickets('kid').net)}` }}
            </span>
          </v-col>
          <v-col cols="4">
            <ul
              class="pl-10"
              style="list-style-type: disc;">
              <li>
                <span>
                  ไม่รวมตั๋วเครื่องบิน
                </span>
              </li>
            </ul>
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span
              v-if="formData.package"
              class="accent--text">
              {{ `${showNumberFormat(getPackage('kid').withOutTicket)} x ${getTickets('kid', false).quantity}` }}
            </span>
            <span v-else>
              โปรดเลือกแพ็กเกจทัวร์
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3"
            class="text-right">
            <span v-if="formData.package">
              {{ `฿ ${showNumberFormat(getTickets('kid', false).net)}` }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10">
            <span class="font-weight-bold">
              ประเภทห้อง
            </span>
          </v-col>
          <template v-for="(room, i) in getRooms()">
            <v-col
              :key="`room-name-${i}`"
              cols="4">
              <ul
                class="pl-10"
                style="list-style-type: disc;">
                <li>
                  <span>
                    {{ room.name }}
                  </span>
                </li>
              </ul>
            </v-col>
            <v-col
              :key="`room-quantity-${i}`"
              cols="4"
              md="3">
              <span class="accent--text">
                {{ `${showNumberFormat(room.price)} x ${room.quantity}` }}
              </span>
            </v-col>
            <v-col
              :key="`room-net-${i}`"
              cols="4"
              md="3"
              class="text-right">
              <span>
                {{ `฿ ${showNumberFormat(room.net)}` }}
              </span>
            </v-col>
          </template>
          <v-col
            cols="12"
            md="10">
            <span class="font-weight-bold">
              อัพเกรดที่นั่ง
            </span>
          </v-col>
          <template v-for="(seat, i) in getSeats()">
            <v-col
              :key="`seat-name-${i}`"
              cols="4">
              <ul
                class="pl-10"
                style="list-style-type: disc;">
                <li>
                  <span>
                    {{ seat.name }}
                  </span>
                </li>
              </ul>
            </v-col>
            <v-col
              :key="`seat-quantity-${i}`"
              cols="4"
              md="3">
              <span class="accent--text">
                {{ `${showNumberFormat(seat.price)} x ${seat.quantity}` }}
              </span>
            </v-col>
            <v-col
              :key="`seat-net-${i}`"
              cols="4"
              md="3"
              class="text-right">
              <span>
                {{ `฿ ${showNumberFormat(seat.net)}` }}
              </span>
            </v-col>
          </template>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="4">
            <span class="font-weight-bold">
              โค้ดส่วนลด
            </span>
          </v-col>
          <v-col
            cols="4"
            md="3">
            {{ formData.discountCode || '-' }}
          </v-col>
          <v-col
            cols="4"
            md="3">
            <v-text-field
              v-model.number="formData.discountPrice"
              type="number"
              color="primary"
              prepend-inner-icon="mdi-currency-thb"
              :rules="[
                (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
              ]"
              outlined
              hide-details
              dense
              disabled />
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="4">
            <span class="font-weight-bold">
              ปรับยอดรวม
            </span>
          </v-col>
          <v-col cols="4">
          </v-col>
          <v-col
            cols="4"
            md="2">
            <v-text-field
              v-model.number="formData.adjustPrice"
              type="number"
              color="primary"
              :prepend-inner-icon="formData.adjustType === 'add' ? 'mdi-plus' : 'mdi-minus'"
              :rules="[
                (v) => v >= 0 || 'กรุณาระบุไม่ต่ำกว่า 0'
              ]"
              outlined
              hide-details
              dense
              disabled />
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col
            cols="4"
            md="3">
            <span class="font-weight-bold">
              ราคารวมสุทธิ
            </span>
          </v-col>
          <v-col
            cols="8"
            md="7"
            class="text-right">
            <span class="font-weight-bold">
              {{ `฿ ${showNumberFormat(formData.net)}` }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrdersProvider from '@/resources/orders.provider'
import ToursProvider from '@/resources/tours.provider'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import PassengersSection from '../components/PassengersSection.vue'

const OrdersService = new OrdersProvider()
const ToursService = new ToursProvider()

export default {
  components: {
    ImageUploader,
    FileUploader,
    PassengersSection
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    telBoxRules: [
      (v) => (v ? (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง') : true)
    ],
    emailBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    postcodeRules: [
      (v) => `${v}`.length <= 5 || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    tourQuery: {
      page: 1,
      limit: 20,
      search: ''
    },
    searchClearing: false,
    searchTimeout: false,
    formData: {
      orderNo: '',
      slipImageName: '',
      slipImageUrl: '',
      tour: null,
      member: null,
      package: null,
      booker: {
        age: 'adult',
        memberId: '',
        prefix: 'mr',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        tel: '',
        citizenNo: '',
        room: {
          name: '',
          price: 0
        },
        insurances: [],
        seat: {
          name: '',
          price: 0
        },
        meal: {
          name: '',
          price: 0
        },
        includeTicket: true
      },
      followers: [],
      rooms: [
        {
          name: '',
          price: 0,
          qty: 0,
          capacity: 1,
          roommates: ['']
        }
      ],
      seats: [
        {
          name: '',
          description: '',
          price: 0,
          qty: 0
        }
      ],
      passports: [],
      passengers: {
        adultWithTicket: 1,
        adultWithOutTicket: 0,
        kidWithTicket: 0,
        kidWithOutTicket: 0
      },
      documentAddress: {
        firstName: '',
        lastName: '',
        tel: '',
        address: '',
        road: '',
        alley: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: ''
      },
      note: '',
      discountCode: '',
      discountPrice: 0,
      adjustPrice: 0,
      adjustType: 'add',
      adjustNote: '',
      net: 0,
      state: 'confirmed',
      payments: []
    },
    ages: [
      {
        text: 'ผู้ใหญ่',
        value: 'adult'
      },
      {
        text: 'เด็ก 0 - 12 ปี',
        value: 'kid'
      }
    ],
    prefixes: [
      {
        text: 'นาย',
        value: 'mr'
      },
      {
        text: 'นาง',
        value: 'ms'
      },
      {
        text: 'นางสาว',
        value: 'mrs'
      }
    ],
    files: [],
    tours: [],
    followerFocusIndex: -1,
    isPaid: false,
    states: [
        {
          text: 'รอชำระเงิน',
          value: 'confirmed'
        },
        {
          text: 'ชำระเงินเสร็จสิ้น',
          value: 'paid'
        },
        {
          text: 'ยกเลิก',
          value: 'canceled'
        }
      ]
  }),
  computed: {
    tourRooms () {
      const tour = this.tours.find((t) => t.id === this.formData.tour?.id)

      if (!tour?.rooms?.length) {
        return []
      }

      return tour.rooms.map((r) => ({
          text: r.price ? `${r.name} ราคา ${this.showNumberFormat(r.price)} บาท` : r.name,
          name: r.name,
          price: r.price,
          capacity: r.capacity
        }))
    },
    filterRooms () {
      const currentRooms = this.formData.rooms.map((r) => r.name).filter(Boolean)

      return this.tourRooms.map((r) => ({
        ...r,
        disabled: currentRooms.includes(r.name)
      }))
    },
    tourSeats () {
      if (!this.formData.tour?.seats?.length) {
        return []
      }

      return this.formData.tour.seats.map((r) => ({
          text: r.price ? `${r.name} ราคา ${this.showNumberFormat(r.price)} บาท` : r.name,
          name: r.name,
          price: r.price
        }))
    },
    filterSeats () {
      const currentSeats = this.formData.seats.map((r) => r.name).filter(Boolean)

      return this.tourSeats.map((r) => ({
        ...r,
        disabled: currentSeats.includes(r.name)
      }))
    },
    availableSeats () {
      // const selected = this.formData.seats.reduce((sum, s) => sum + s.qty, 0)
      // const diff = this.passengersQty - selected
      const seats = Array.from(Array(this.passengersQty), (_, index) => index + 1)

      return seats.map((s) => ({
        text: `${s}`,
        value: s
        // disabled: s > diff
      }))
    },
    tourInsurances () {
      return this.formData.tour?.insurances || []
    },
    tourPackages () {
      const tour = this.tours.find((t) => t.id === this.formData.tour?.id)

      return tour?.packages || []
    },
    packageText () {
      if (!this.formData.package?.startDate || !this.formData.package?.endDate) {
        return ''
      }

      const startDate = this.$options.filters.dateFormat(this.formData.package.startDate)
      const endDate = this.$options.filters.dateFormat(this.formData.package.endDate)

      return `${startDate} - ${endDate}`
    },
    passengersQty () {
      return Object.keys(this.formData.passengers).reduce((sum, key) => sum + this.formData.passengers[key], 0)
    }
  },
  mounted () {
    this.getItemById()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'OrderList' })
    },
    onEdit () {
      this.$router.push({
        name: 'OrderEdit',
        params: {
          id: this.$route.params.id
        }
      })
    },
    getFollowers (includeBooker = true) {
      if (includeBooker) {
        return [
          this.formData.booker,
          ...this.formData.followers
        ]
      }

      return this.formData.followers
    },
    getPackage (type) {
      return this.formData.package && this.formData.package[type]
        ? this.formData.package[type]
        : {
            price: 0,
            withOutTicket: 0
          }
    },
    getAdults () {
      return this.getFollowers().filter((t) => t.age === 'adult')
    },
    getKids () {
      return this.getFollowers().filter((t) => t.age === 'kid')
    },
    getTickets (type, includeTicket = true) {
      const travelers = type === 'adult' ? this.getAdults() : this.getKids()
      const quantity = travelers.filter((t) => t.includeTicket === includeTicket).length

      return {
        quantity,
        net: includeTicket ? this.getPackage(type).price * quantity : this.getPackage(type).withOutTicket * quantity
      }
    },
    getRooms () {
      return this.tourRooms
        .map((r) => {
          const found = this.formData.rooms.filter((t) => t.name === r.name)
          const quantity = found.reduce((sum, s) => sum + s.qty, 0)

          return {
            name: r.name,
            price: r.price,
            capacity: r.capacity,
            quantity,
            net: r.price * quantity
          }
        })
        .filter((r) => r.quantity)
    },
    getSeats () {
      return this.tourSeats
        .map((r) => {
          const found = this.formData.seats.find((t) => t.name === r.name)

          return {
            name: r.name,
            price: r.price,
            quantity: found?.qty || 0,
            net: (found?.price || 0) * (found?.qty || 0)
          }
        })
        .filter((r) => r.quantity)
    },
    getInsurances () {
      if (!this.formData.tour) {
        return []
      }

      const travelers = this.getFollowers()
      const mapped = travelers.reduce((obj, t) => {
        const tmpObj = { ...obj }
        const insurances = this.formData.tour.insurances.filter((i) => t.insurances.includes(i.name))

        insurances.forEach((i) => {
          const quantity = (tmpObj[i.name]?.quantity || 0) + 1

          tmpObj[i.name] = {
            name: i.name,
            price: i.price,
            quantity,
            net: quantity * i.price
          }
        })

        return tmpObj
      }, {})

      return Object.values(mapped)
    },
    showNumberFormat (number) {
      const num = Number.parseFloat(number)

      if (num === 0 || num) {
        return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '-'
    },
    selectFollower (index) {
      this.followerFocusIndex = index
    },
    async getTours (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.tours = []
          this.tourQuery.page = 1
        }

        const { data } = await ToursService.getItemByName(this.tourQuery)

        this.tours = data.results
      } catch (error) {
        console.error('getTours', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await OrdersService.getItemByOrderNo(this.$route.params.id)

        this.tourQuery.search = data.tour.name

        const { data: tours } = await ToursService.getItemByName(this.tourQuery)

        this.tours = tours.results

        if (data.followers.length) {
          this.followerFocusIndex = 0
        }

        this.isPaid = data.state === 'paid'
        this.formData = {
            ...data,
            tour: this.tours.find((t) => t.id === data.tour.id),
            booker: {
              ...data.booker,
              insurances: data.booker.insurances.map((i) => i.name)
            },
            followers: data.followers.map((f) => ({
              ...f,
              insurances: f.insurances.map((i) => i.name)
            })),
            adjustPrice: data?.adjustPrice || 0,
            adjustType: data?.adjustType || 'add',
            adjustNote: data?.adjustNote || ''
          }
        this.formData.passports = data?.passports?.map((p) => ({
          file: null,
          preview: p,
          mediaUrl: p
        })) || []
        this.files = data.slipImageUrl ? [
          {
            name: data.slipImageName,
            mediaUrl: data.slipImageUrl
          }
        ] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.no-shadow-panel::before {
  box-shadow: none !important;
}
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.v-size--x-large {
  height: 64px !important;
  border-radius: 8px !important;
  letter-spacing: 0em !important;
}
.discount-btn {
  font-size: 14px;
  color: #026EAA;
  cursor: pointer;
  text-decoration: underline;
}
.discount-btn:disabled {
  color: #828282;
}
</style>
